.kustomer-mobile-active body #kustomer-ui-sdk-iframe {
    display: block;
    border: 0px;
    width: 90% !important;
    height: 90% !important; 
    position: fixed;
    bottom: 5% !important;
    right: 5% !important;
    z-index: 2147483000; 
    transition: width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
